.paginationNav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin: 16px;
    button {
      border: none;
      padding: 8px;
      margin: 0;
      background: transparent;
      color: var(--font-color-gray);
      font-size: 12px;
      &:hover {
        cursor: pointer;
      }
    }
    button[disabled] {
      cursor: revert;
      color: #d3d3d3;
    }
    button[aria-current] {
      color: var(--font-color-black);
      font-weight: 1000;
      cursor: revert;
    }
    input{
      width: 35px;
      border: '3px solid #0A2B83';
      &::-webkit-inner-spin-button{
        -webkit-appearance: none;
      }
    }
    p, input{
      height:100%;
      // margin-top: 4px;
      // margin-bottom: 4px;
      color :#0A2B83;
      font-size: 12px;
    }
    div.goToBtnsArea {
      display: flex;
      align-items: center;
      gap: 18px;
      margin-left: 18px;
      div.inputArea {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }