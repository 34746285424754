:root {
  --header-height: 75px;
  --content-min-height: calc(100vh - 75px);
}
* { padding: 0; margin: 0; box-sizing: border-box; font-weight: normal; }
a { text-decoration: none; color: inherit; line-height: 1; }
li { list-style: none; }
button {
  font-family: 'Noto Sans KR','Black Han Sans','Noto Sans','S-CoreDream-4Regular';
  line-height: 1;
}
button img {
  pointer-events: none;
}
input {
  outline: none;
}
body {
  margin: 0;
  font-family: 'Noto Sans KR','Noto Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.2;
  color: var(--font-color-black);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
  min-width: 1200px;
  min-height: 100vh;
}

input[type='submit'], button {
  cursor: pointer;
}

select::-ms-expand { 
	display: none;
}
select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* body *::-webkit-scrollbar {
  display: block;
}

body *::-webkit-scrollbar-corner {
  background: transparent;
}

body *::-webkit-scrollbar-button {
  display: none;
}

body *::-webkit-scrollbar-thumb {
  outline: none;
  background-color: #0A2B83;
  border-radius: 10px;
}

body *::-webkit-scrollbar-track {
  display: none;
} */

a[disabled] {
  pointer-events: none;
  opacity: 0;
}


.selectBox {
  color: #0A2B83;
}
