.membersPage {
    .memberMainArea {
        width: 1100px;

        .pageTitle {
            padding-bottom: 36px;
        }

        .pageContent {
            position: relative;

            .btnsArea {
                position: absolute;
                top: -50px;
                right: 0;
                display: flex;
                justify-content: flex-end;

                button {
                    width: 96px;
                    height: 28px;
                    font-family: 'LeferiPointWhite';
                    font-size: 14px;
                    line-height: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1pt solid #29CCE2;
                    border: none;
                    background-color: #45BFCE;
                    color: #FFF;
                    border-radius: 16px;

                    &:not(:last-child) {
                        margin-right: 19px;
                    }

                    &.newUser {
                        width: 120px;
                    }

                    &.deleteUser {}
                }
            }

            table.membersTable {
                width: 100%;
                border-spacing: 0;

                thead {
                    background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);

                    tr {
                        th {
                            // background-color: #E9EDF5;
                            // border: 5pt solid #FFF;
                            height: 40px;
                            font-family: 'LeferiPointWhite';
                            font-size: 16px;
                            color: #FFF;

                            &:not(:last-child) {
                                border-right: 1px solid #FFF;
                            }

                            &.userChkbox {
                                width: 64px;
                            }

                            &.userName {
                                width: 140px;
                            }

                            &.userEmail {
                                width: 300px;
                            }

                            &.userCompany {
                                width: 150px;
                            }

                            &.userRank {
                                width: 120px;
                            }

                            &.data {
                                // width: 124px;
                            }

                            &.editBtnTh {
                                width: 100px;
                            }
                        }
                    }
                }

                tbody tr td {
                    height: 27px;
                    text-align: center;
                    font-family: 'LeferiPointWhite';
                    font-size: 12px;
                    color: #4D4D4D;

                    * {
                        font-family: 'LeferiPointWhite';
                        font-size: 12px;
                        color: #4D4D4D;
                    }

                    border-left: 0.25pt solid #808080;
                    border-bottom: 0.25pt solid #808080;

                    &:last-child {
                        border-right: 0.25pt solid #808080;

                        button {
                            width: 40px;
                            height: 19px;
                            background-color: #0A2B83;
                            font-size: 12px;
                            // line-height: 18px;
                            line-height: 1;
                            color: #FFF;
                            border-radius: 2px;
                            border: 1px solid #0A2B83;
                            outline: none;
                            padding-bottom: 1px;

                            &:hover {
                                background-color: #FFF;
                                border: 1px solid #0A2B83;
                                color: #0A2B83;
                            }
                        }
                    }

                    &.userRank {
                        >.innerDiv {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        div.selectBox {

                            * {
                                line-height: 1;
                            }

                            div.control {
                                min-height: auto;
                                width: 88px;
                                height: 18px;
                                border: 0.5pt solid #0A2B83;
                                border-radius: 3px;
                                text-align: start;
                                // box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.45);

                                div.singleVal {
                                    color: #0A2B83;
                                }

                                div.valueContainer {
                                    padding: 0 15px;
                                }

                                span.arrowIcon {
                                    width: 16px;
                                    padding-right: 5px;
                                    // border-left: 0.25pt solid #808080;
                                    background-color: transparent;

                                    img {
                                        width: 12px;
                                        // height: 6px;
                                    }
                                }
                            }

                            .options {
                                margin-top: 8px;
                                box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.65);

                                .option {
                                    height: 20px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    padding-left: 15px;
                                }
                            }
                        }

                    }

                    &.data {

                        // display: flex;
                        // justify-content: center;
                        // align-items: center;
                        >.innerDiv {
                            width: 225px;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 27px;
                            padding: 0 20px;
                            .dataDropDown {
                                width: 100%;
                                button.dropDownBtn {
                                    width: 100%;
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    .memberEditArea {
        .pageTitle {
            padding-bottom: 164px;
        }

        .pageContent {
            width: 100%;
            display: flex;
            justify-content: center;

            form {
                width: 898px;
                height: 447px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                table {
                    width: 100%;
                    border-spacing: 0;

                    td {
                        height: 70px;

                        * {
                            font-size: 16px;
                            font-family: 'LeferiPointWhite';

                        }

                        &.labelTd {
                            width: 165px;
                            // border: 0.25pt solid #808080;
                            border: none;
                            // background-color: #E9EDF5;
                            background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);
                            text-align: center;
                            color: #FFF;
                            border-top-left-radius: 6px;
                            border-bottom-left-radius: 6px;
                        }

                        &.inputTd {
                            width: 733px;
                            color: #4D4D4D;
                            border-top: 0.25pt solid #808080;
                            border-right: 0.25pt solid #808080;
                            border-bottom: 0.25pt solid #808080;
                            background-color: #FFF;
                            padding: 0 20px;
                            border-top-right-radius: 6px;
                            border-bottom-right-radius: 6px;

                            input {
                                width: 100%;
                                height: 100%;
                                padding: 0 24px;
                                outline: none;
                                border: none;
                                background-color: transparent;
                            }

                            div.selectBox {
                                width: 128px;
                                height: 27px;

                                div.control {
                                    min-height: auto;
                                    width: 128px;
                                    height: 27px;
                                    border: 0.25pt solid #0A2B83;
                                    border-radius: 3px;
                                    box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.45);

                                    * {
                                        line-height: 1;
                                    }

                                    div.singleVal {
                                        color: #0A2B83;
                                    }

                                    div.valueContainer {
                                        padding: 0 15px;
                                    }

                                    span.arrowIcon {
                                        width: 22px;
                                        padding-right: 8px;
                                        // width: 55px;
                                        // border-left: 0.25pt solid #808080;
                                        background-color: transparent;

                                        img {
                                            width: 14px;
                                            height: 6px;
                                        }
                                    }
                                }

                                .options {
                                    margin-top: 9px;
                                    box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.65);

                                    .option {
                                        height: 28px;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                    }
                                }

                            }

                        }
                    }

                }
                div.formBtnArea {
                    width: 202px;
                    display: flex;
                    justify-content: space-between;

                    .formBtn {
                        width: 94px;
                        height: 34px;
                        font-family: 'LeferiPointWhite';
                        font-size: 14px;
                        outline: none;
                        line-height: calc(34px - 0.5pt);

                        &.submit {
                            border: 0.25pt solid #0A2B83;
                            background-color: #0A2B83;
                            color: #FFF;
                        }

                        &.cancel {
                            border: 0.25pt solid #808080;
                            background-color: #E9EDF5;
                            color: #4D4D4D;
                        }
                    }
                }
            }
        }

        div.selectBox {

            span.arrowIcon {
                background-color: #E9EDF5;

                img {
                    width: 11px;
                    height: 5px;
                }
            }

        }
    }
}