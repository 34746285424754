.dataContentArea {
    padding-left: 181px !important;
    padding-right: 75px !important;
}

.dataMainArea {
    position: relative;

    button.refreshBtn {
        position: absolute;
        top: -6px;
        right: 40px;
        background-color: transparent;
        border: none;
        outline: none;
    }

    div.selectBox {
        position: relative;

        // z-index: inherit;
        // overflow: hidden;    
        div.control {
            box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.45);

            span.arrowIcon {
                background-color: transparent;
            }
        }

        select {
            position: relative;
            z-index: 2;
            background-color: transparent;
            // width: inherit;
            width: 100%;
            // height: inherit;
            height: 100%;
            border: none;
            outline: none;

            option {
                // line-height: 266px;
            }
        }

        span.arrowIcon {
            position: absolute;
            top: 0;
            right: 0;
            z-index: inherit;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;

            img {
                object-fit: contain;
            }
        }

        select:focus+.arrowIcon img {
            transform: rotate(180deg);
        }
    }

    .pageTitle {
        margin-bottom: 41px;
    }

    .pageContent {

        .btnsArea {
            // width: 1590px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 22px;

            .periodBtnsArea {
                width: 953px;

                form {
                    width: inherit;
                    display: flex;
                    align-items: center;

                    span.datePickerLabel {
                        font-family: 'LeferiPointSpecial';
                        font-size: 16px;
                        color: #000000;
                        margin-right: 10px;
                    }

                    div.datePickerArea {
                        width: 510px;
                        display: flex;
                        justify-content: space-between;
                        margin-right: 20px;

                        >span {
                            font-family: 'LeferiPointWhite';
                            font-size: 16px;
                            color: #0A2B83;
                            line-height: 33px;
                        }
                    }

                    div.selectBox {
                        font-family: "LeferiPointWhite";
                        font-size: 14px;

                        * {
                            color: #0A2B83;
                        }

                        div.control {
                            width: 160px;
                            height: 33px;
                            min-height: auto;
                            border: 0.5pt solid #0A2B83;
                            border-radius: 5px;
                            box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.45);
                            text-align: center;
                            padding-right: 30px;

                            div.singleVal {
                                color: #0A2B83;
                            }

                            span.arrowIcon {
                                width: 30px;
                            }
                        }

                        .options {
                            margin-top: 7px;
                            padding: 9px 2px;
                            width: 138px;
                            box-shadow: 0 2px 2px 0px rgba($color: #000000, $alpha: 0.35);
                            border-radius: 12px;

                            .option {
                                height: 32px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .selected {
                                background-color: #E9EDF5;
                            }
                        }

                        span.arrowIcon {
                            width: 30px;

                            img {
                                width: 13px;
                                height: 7px;
                            }
                        }
                    }

                    input.submitPeriod {
                        margin-left: 42px;
                        margin-right: 9px;
                        width: 65px;
                        height: 34px;
                        outline: none;
                        border: none;
                        border-radius: 3px;
                        background-color: #0A2B83;
                        color: #FFF;
                        line-height: 1;
                        padding-bottom: 1px;
                        font-family: 'NanumSquare';
                        font-size: 16px;
                    }

                    button.resetPeriod {
                        width: 95px;
                        height: 33px;
                        outline: none;
                        border-radius: 3px;
                        border: 1pt solid #0A2B83;
                        background-color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            display: block;
                            color: #0A2B83;
                            margin-right: 11px;
                            line-height: 1;
                            font-family: 'NanumSquare';
                            font-size: 16px;
                        }
                    }
                }
            }


            .btnsArea {
                // width: calc(390px + 137px);
                width: 253px;
                height: 33px;
                margin-bottom: 0;
                margin-left: 100px;
                overflow: hidden;

                .viewModeBtnsArea {
                    display: flex;
                    height: 26px;

                    .saveCSVBtn {
                        font-family: 'LeferiPointWhite';
                        font-size: 14px;
                        color: #FFF;
                        width: 99px;
                        height: 26px;
                        background-color: #1254B5;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 13px;
                        margin-right: 12px;
                        line-height: 1;

                        img {
                            width: 15px;
                            height: 13px;
                            margin-right: 6px;
                        }
                    }

                    button.createData {
                        width: 65px;
                        height: 26px;
                        font-family: 'LeferiPointWhite';
                        font-size: 14px;
                        line-height: 1;
                        border-radius: 12px;
                        color: #FFF;

                        background-color: #45BFCE;
                        border: none;
                        outline: none;
                        margin-right: 12px;

                        &:disabled {
                            background-color: #7F93AB;
                            color: #FFF;
                            cursor: default;
                        }
                    }

                    button.deleteData {
                        width: 65px;
                        height: 26px;
                        border-radius: 13px;
                        line-height: 1;
                        font-family: 'LeferiPointWhite';
                        font-size: 14px;
                        color: #FFF;
                        background-color: #45BFCE;
                        border: none;
                        outline: none;

                        &:disabled {
                            background-color: #808080;
                            color: #CCC;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .tblScroll {
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: 22px;
            }

            &::-webkit-scrollbar-track {
                background: none;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #0A2B83;
                border: 7px solid #ffffff;
                border-radius: 15px;
            }
        }

        table {
            // width: 1590px;
            width: 100%;
            border-spacing: 0;

            thead {
                background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);

                th {
                    >div {
                        margin: 0 auto;
                        min-width: 67px;
                    }

                    &:first-child:not(.secondTh, .thirdTh) {
                        border-top-left-radius: 6px;
                    }

                    &:last-child:not(.secondTh, .thirdTh) {
                        border-top-right-radius: 6px;
                    }

                    // background-color: #E9EDF5;
                    &:not(:first-child:not(.secondTh, .thirdTh)) {
                        border-left: 0.75pt solid #FFF;
                    }

                    // border-bottom: 0.75pt solid #FFF;
                    font-family: 'LeferiPointWhite';
                    font-size: 16px;
                    // color: #4D4D4D;
                    color: #FFF;

                    &:last-child:not(.secondTh) {
                        // border-right: 0.75pt solid #B3B3B3;
                    }

                    &.secondTh,
                    &.thirdTh {
                        border-top: 0.75pt solid #FFF;
                    }

                    &.title {
                        border-bottom: none;

                        // min-width: 120px;
                        >div {
                            height: 39px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                    }

                    &.secondTh>div {
                        height: 28px;
                    }

                    &.detail>div {
                        height: 57px;
                    }

                    &.thirdTh {
                        >div {
                            height: 29px;
                        }
                    }

                    &.dataChkbox>div {
                        width: 31px;
                    }

                    &.dataTime>div {
                        width: 164px;
                    }

                    div.withDropdown {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    div.nameNSorting {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            line-height: 1;
                        }

                        button.sortingBtn {
                            width: 13px;
                            height: 13px;
                            outline: none;
                            border: none;
                            background-color: transparent;
                            border-radius: 6.5px;
                            margin-left: 4px;

                            img {
                                width: inherit;
                                height: inherit;
                                object-fit: contain;
                            }

                        }
                    }

                    div.selectBox {
                        margin-top: 13px;

                        border: none;
                        border-radius: 3px;
                        background-color: #FFF;
                        box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.45);
                        font-family: 'LeferiPointWhite';

                        * {
                            line-height: 1;
                        }

                        div.control {
                            min-height: auto;
                            width: 57px;
                            height: 23px;

                            div.singleVal {
                                padding-right: 16px;
                                color: #0A2B83;
                                text-align: center;
                            }

                            div.valueContainer {
                                padding: 0;
                            }

                            span.arrowIcon {
                                width: 16px;
                                padding-right: 5px;
                                // border-left: 0.25pt solid #808080;
                                background-color: transparent;
                            }
                        }


                        .options {
                            // margin-top: 8px;
                            box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.65);
                            left: 50%;
                            transform: translateX(-50%);
                            margin-top: 3px;
                            width: fit-content;
                            min-width: 90%;
                            // box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.65);
                            // border: 1px solid transparent;
                            background-image: linear-gradient(#FFF, #FFF), linear-gradient(to right, #45BFCE 0%, #0A2B83 100%);
                            background-origin: border-box;
                            background-clip: content-box, border-box;
                            padding: 1px;

                            .option {
                                height: 20px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding-left: 15px;
                                white-space: nowrap;
                            }

                        }

                        // select {
                        //     // padding-left: 12px;
                        //     padding-right: 22px;
                        //     border-radius: 3px;

                        //     color: #0A2B83;
                        //     font-size: 16px;
                        //     line-height: 1;
                        //     text-align: center;

                        //     option {
                        //         line-height: 1;
                        //     }
                        // }

                        span.arrowIcon {
                            width: 30px;

                            img {
                                width: 13px;
                                height: 7px;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    height: 27px;

                    &:last-child td {
                        border-bottom: 0.25pt solid #808080;
                    }

                    td {
                        min-height: 27px;
                        text-align: center;
                        border-top: 0.25pt solid #808080;
                        border-left: 0.25pt solid #808080;
                        font-family: 'LeferiPointWhite';
                        font-size: 12px;
                        color: #4D4D4D;
                        padding: 0 6px;

                        div.percentageDiv {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            // padding: 0 6px;

                            span {
                                // padding-right: ;
                            }
                        }

                        .customDatePickerArea {
                            width: auto;

                            input[type=text] {
                                width: auto;
                                font-size: 12px;
                                border: none;
                                height: 100%;
                                color: #4D4D4D;
                            }
                        }

                        &.dataModelType,
                        &.dataModelName,
                        &.dataModelNo {
                            position: relative;
                            p {
                                white-space: nowrap;
                                overflow: hidden; // 너비를 넘어가면 안보이게
                                text-overflow: ellipsis; // 글자가 넘어가면 말줄임(...) 표시
                            }

                            div.hintArea {
                                display: none;
                                width: fit-content;
                                z-index: 9;
                                border-radius: 4px;
                                position: absolute;
                                top: calc(100% - 3px);
                                left: 7px;
                                background-image: linear-gradient(to right, #45BFCE 0%, #0A2B83 100%);
                                padding: 1px;
                                overflow: hidden;

                                span.hintSpan {
                                    white-space: nowrap;
                                    display: block;
                                    background-color: #FFF;
                                    border-radius: 2px;
                                    line-height: 1;
                                    // padding: 9px 8px;
                                    border: 10px solid transparent;
                                }
                            }

                            &:hover:not(.menuOpened) {
                                div.hintArea {
                                    display: block;
                                    z-index: 1;
                                }
                            }
                        }


                        div.selectBox {

                            border: none;
                            background-color: #FFF;
                            font-family: 'LeferiPointWhite';
                            height: 28px;

                            * {
                                line-height: 1;
                            }

                            div.control {
                                min-height: auto;
                                width: 100%;
                                height: 100%;
                                box-shadow: none;
                                border: none;

                                div.singleVal {
                                    color: #4D4D4D;
                                    // color: #0A2B83;
                                }

                                div.valueContainer {
                                    // padding: 0 15px;
                                }

                                span.arrowIcon {
                                    width: 16px;
                                    padding-right: 5px;
                                    // border-left: 0.25pt solid #808080;
                                    background-color: transparent;
                                }
                            }


                            .options {
                                left: 50%;
                                transform: translateX(-50%);
                                margin-top: 3px;
                                width: fit-content;
                                min-width: 90%;
                                // box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.65);
                                // border: 1px solid transparent;
                                background-image: linear-gradient(#FFF, #FFF), linear-gradient(to right, #45BFCE 0%, #0A2B83 100%);
                                background-origin: border-box;
                                background-clip: content-box, border-box;
                                padding: 1px;

                                .option {
                                    height: 20px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    padding-left: 15px;
                                    white-space: nowrap;
                                }

                            }
                        }

                        &.dataAbnormalPattern {
                            div.selectBox {
                                .options {
                                    width: 115px;
                                }
                            }
                        }

                        &.detailCol {
                            button {
                                width: 43px;
                                height: 19px;
                                border: none;
                                outline: none;
                                background-color: #0A2B83;
                                line-height: 1;
                                color: #FFF;
                                font-family: "NotoSansKR";
                                font-size: 12px;
                                border-radius: 2px;

                                &:disabled {
                                    background-color: #808080;
                                    color: #CCC;
                                    cursor: default;
                                }
                            }
                        }

                        input.sumDataInput {
                            width: 100%;
                            height: 100%;
                            text-overflow: ellipsis;
                            border: none;
                            outline: none;
                            font-family: 'LeferiPointWhite';
                            font-size: 12px;
                            color: #4D4D4D;
                            text-align: center;

                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                appearance: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                            }

                            &[name="comment"] {
                                padding: 0 6px;
                                text-align: start;
                            }
                        }

                        &:last-child {
                            border-right: 0.25pt solid #808080;
                        }

                        button.detailBtn {
                            width: 43px;
                            height: 19px;
                            background-color: #0A2B83;
                            outline: none;
                            border: none;
                            border-radius: 2px;
                            font-family: 'NotoSansKR';
                            font-size: 12px;
                            line-height: 1;
                            color: #FFF;

                            &:disabled {
                                background-color: #808080;
                                color: #CCC;
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }

        nav.paginationNav {
            // width: 1590px;
            width: 100%;
        }
    }
}

.dataEditArea,
.dataCreateArea {
    .pageTitle {
        margin-bottom: 29px;
    }

    .pageContent {
        form {
            width: inherit;
            height: inherit;
            position: relative;
            width: 1158px;

            table {
                width: 100%;
                border-spacing: 0;

                * {
                    font-family: 'LeferiPointWhite';


                }

                tr {
                    &:first-child {
                        td.labelTd {
                            border-top-left-radius: 7px;
                        }

                        td:not(.labelTd) {
                            border-top: 0.25pt solid #808080;
                        }
                    }

                    &:last-child {
                        td.labelTd {
                            border-bottom-left-radius: 7px;
                        }
                    }

                    td {
                        border-bottom: 0.25pt solid #808080;
                        border-left: 0.25pt solid #808080;
                        height: 27px;

                        &.labelTd {
                            // background-color: #E9EDF5;
                            background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);
                            color: #FFF;
                            text-align: center;
                            width: 170px;
                            border-left: none;
                            border-bottom: 0.25pt solid #FFF;
                            font-size: 16px;

                            &.secondLabel,
                            &.thirdLabel {
                                width: 198px;
                            }
                        }

                        &.inputTd {
                            // min-width: 300px;
                            padding: 0 22px;
                            color: #4D4D4D;
                            font-size: 14px;

                            &.firstInput {
                                width: 187px;
                            }

                            &.secondInput {
                                width: 212px;
                            }

                            .customDatePickerArea input[type=text] {
                                color: #4D4D4D;
                                font-size: 14px;
                            }

                            input {
                                height: 100%;
                                border: none;
                                outline: none;
                            }

                            .commentArea {
                                padding: 7px 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                textarea {
                                    width: 100%;
                                    resize: vertical;
                                    min-height: 100%;
                                    border: none;
                                    outline: none;
                                }
                            }

                            div.selectArea {
                                width: 158px;
                                height: 23px;

                                div.selectBox {
                                    width: fit-content;
                                    color: #4D4D4D;

                                    div.control {
                                        min-height: auto;
                                        width: 83px;
                                        height: 23px;
                                        border: 0.25pt solid #0A2B83;
                                        border-radius: 2px;

                                        // box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.45);
                                        &.large {
                                            width: 128px;
                                        }

                                        * {
                                            line-height: 1;
                                        }

                                        div.valueContainer {
                                            padding: 0 8px;
                                        }

                                        span.arrowIcon {
                                            width: 18px;
                                            padding-right: 7px;
                                            background-color: transparent;
                                            border: none;

                                            img {
                                                width: 14px;
                                                height: 8px;
                                            }
                                        }
                                    }

                                    .options {
                                        margin-top: 4px;
                                        // overflow: hidden;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: fit-content;
                                        min-width: 90%;
                                        background-image: linear-gradient(#FFF, #FFF), linear-gradient(to right, #45BFCE 0%, #0A2B83 100%);
                                        background-origin: border-box;
                                        background-clip: content-box, border-box;
                                        padding: 1px;

                                        .option {
                                            // padding-left: 23px;
                                            // padding-right: 23px;
                                            color: #4D4D4D;
                                            height: 20px;
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            padding-left: 15px;
                                            padding-right: 15px;
                                            white-space: nowrap;
                                        }

                                        .selected {
                                            background-color: #E9EDF5;
                                        }
                                    }

                                    select {
                                        padding-left: 8px;
                                        padding-right: 38px;
                                        border-radius: 2px;
                                        font-family: 'LeferiPointWhite';
                                        color: #4D4D4D;
                                        font-size: 16px;
                                        line-height: calc(37px - 0.5pt);

                                        &.statusDropdown {
                                            padding-left: 29px;
                                        }

                                        // line-height: 1;

                                        option {
                                            // line-height: 1;
                                        }
                                    }

                                    span.arrowIcon {
                                        width: 38px;
                                        background-color: #E9EDF5;
                                        border-left: 0.25pt solid #808080;


                                    }
                                }
                            }
                        }

                        &:last-child {
                            border-right: 0.25pt solid #808080;
                        }

                    }
                }
            }

            .formBtnArea {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -77px;
                right: 0px;

                button,
                input[type="submit"] {
                    width: 94px;
                    height: 34px;
                    font-family: 'LeferiPointWhite';
                    font-size: 14px;
                    outline: none;
                    line-height: calc(34px - 0.5pt);

                    &.backBtn {
                        // width: ;
                        line-height: 1;
                    }

                    &.submitBtn {
                        background-color: #0A2B83;
                        color: #FFF;
                        border: 0.25pt solid #0A2B83;
                    }

                    &.cancelBtn {
                        margin-left: 14px;
                        background-color: #E9EDF5;
                        color: #4D4D4D;
                        border: 0.25pt solid #808080;
                        border-radius: 0;
                    }
                }
            }
        }
    }

    &.dataCreateArea {
        .pageContent form table tbody tr:last-child td {
            height: 78px;
        }
    }
}