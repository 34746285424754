.dataDropDown {
    position: relative;

    button.dropDownBtn {
        width: 108px;
        height: 18px;
        background-color: #FFF;
        border: 1px solid #0A2B83;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        p {
            width: calc(100% - 19px);
            height: 100%;
            padding: 0 5px;
            white-space: nowrap;
            overflow: hidden; // 너비를 넘어가면 안보이게
            text-overflow: ellipsis; // 글자가 넘어가면 말줄임(...) 표시
        }

        img {
            width: 12px;
            margin-right: 5px;
        }

        span.hint {
            display: none;
            position: absolute;
            top: 20px;
            left: 4px;
            z-index: 1;
            background-color: #FFF;
            width: fit-content;
            white-space: nowrap;
            padding: 4px;
            border: 1px solid #0A2B83;
            border-radius: 3px;
        }

        &.closed:hover {
            span.hint {
                display: inline-block;
            }
        }
    }

    div.dropDownMenu {
        position: absolute;
        background-color: #FFF;
        // width: 108px;
        width: fit-content;
        z-index: 2;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 0px 2px rgba($color: #000000, $alpha: 0.65);

        &.opened {
            display: flex;
            flex-direction: column;

        }

        &.closed {
            display: none;
        }

        ul.chkBoxUl {
            li.chkBoxLi {
                button.chkBox {
                    padding: 5px;
                    background-color: #FFF;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    // width: 108px;
                    height: 25px;
                    cursor: pointer;
                    border: none;
                    outline: none;

                    span {
                        display: inline-block;
                        // width: 80px;
                        pointer-events: none;
                        user-select: none;
                        white-space: nowrap;
                        overflow: hidden; // 너비를 넘어가면 안보이게
                        text-overflow: ellipsis; // 글자가 넘어가면 말줄임(...) 표시
                    }

                    div.customChkBox {
                        width: 14px;
                        height: 14px;
                        border-radius: 3px;
                        border: 0.5px solid #0A2B83;
                        background-color: #FFF;
                        margin-right: 8px;

                        >img {
                            margin: 0;
                        }
                    }
                }

                &.chked {
                    button.chkBox {
                        div.customChkBox {
                            background-color: #0A2B83;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 9px;
                                height: 8px;
                            }
                        }
                    }
                }
            }
        }

        div.submitBtnArea {
            position: relative;
            width: 100%;
            height: 22px;
            background-color: #F2F2F2;
            display: flex;
            justify-content: flex-end;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: calc(100% - 2px);
                height: 1px;
                left: 50%;
                transform: translateX(-50%);
                top: -0.1px;
                background-color: #0A2B83;
            }

            button.submitBtn {
                background-color: transparent;
                border: none;
                outline: none;
                border-radius: 0;
                width: 33px;
                height: 100%;
            }
        }
    }
}